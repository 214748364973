

































































































































import { Api, BadgeKey, ChampionBadgeOption, ChampionFilters, ChampionModel, PagedResults, TableState } from '@/edshed-common/api'
import { debounce } from 'lodash'
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'
@Component({
  name: 'Champions'
})

export default class Champions extends Mixins(ComponentHelper) {
  loading: boolean = false
  searchTerm: string = ''
  userData: PagedResults<ChampionModel> = { items: [], total: 0 }
  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'badgeStarted',
    dir: 'desc',
    term: ''
  }

  ChampionBadgeOption = ChampionBadgeOption
  BadgeKey = BadgeKey
  dateFormat = this.dateFormat

  completedOnly: ChampionBadgeOption = 'All Badges'
  selectedOption: BadgeKey | null = null;

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getChampions()
    }
  }

  @Watch('selectedOption')
  selectedOptionChanges () {
    this.getChampions()
  }

  @Watch('completedOnly')
  completedOptionChanges () {
    this.getChampions()
  }

  formatOption (optionKey: string) {
    if (!optionKey) {
      return
    }
    const words = optionKey.split('-')

    for (let i = 0; i < words.length - 1; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }

    words[words.length - 1] = words[words.length - 1].toUpperCase()

    return words.join(' ')
  }

  searchDidChange () {
    this.table.page = 1
    this.getChampions()
  }

  setSearchingDebounced = debounce(() => {
    this.searchDidChange()
  }, 1000)

  onPageChange (page) {
    this.table.page = page
    this.getChampions()
  }

  onSortChange (sort: string, order: 'asc' | 'desc') {
    this.table.sort = sort
    this.table.dir = order
    this.getChampions()
  }

  async getChampions () {
    try {
      this.loading = true
      const params: ChampionFilters = {
        completedOnly: this.completedOnly
      }

      if (this.selectedOption) {
        params.badgeType = this.selectedOption
      }

      this.userData = await Api.getChampionUsers({ ...this.table }, params)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load Champions',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }
}
